body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-top: 80px; */
  background-color:'#323232';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home {
  text-align: center;
  background-color:'#323232';
}

/* .sticky-nav {
  position: sticky;
  top: 0;
} */

.color-nav {
  background-color: #323232;
}

.nav-dropdown {
  background-color: #323232;
}

.nav-link{
  font-family: "GT-Walsheim","Helvetica Neue",Arial,sans-serif;
  margin: 20px;
  /* set hover color */

}

.nav-link:hover{
  color: #B01C33 !important;
}

.custom-header {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

/* .container {
  background-color: #323232;
  color: white;
  padding-top: 100px;
} */

/* .custom-header {
  font-family: 'Roboto', sans-serif;
  font-size: 50px;
} */

.hr {
  border-color: white;
}

.center {
  display: flex;
  justify-content: center;
}